var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "c-card",
    {
      staticClass: "cardClassDetailInfo safetyGearRevipientSign",
      staticStyle: { width: "300px" },
      attrs: {
        title: `서명보기 [${_vm.props.row.signDt || ""}]`,
        topClass: "topcolor-orange",
      },
    },
    [
      _c("template", { slot: "card-detail" }, [
        _vm.signFlag
          ? _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12" },
              [
                _c("VueSignaturePad", {
                  ref: "signaturePad",
                  attrs: { width: "200px", height: "200px" },
                }),
              ],
              1
            )
          : _c(
              "div",
              {
                staticClass: "col-xs-12 col-sm-12",
                staticStyle: { "text-align": "center" },
              },
              [_c("span", [_vm._v("🔥 서명이 되지 않았습니다")])]
            ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }