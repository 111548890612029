<template>
  <c-card :title="`서명보기 [${props.row.signDt || ''}]`" class="cardClassDetailInfo safetyGearRevipientSign" topClass="topcolor-orange" style="width:300px">
    <template slot="card-detail">
      <div v-if="signFlag" class="col-xs-12 col-sm-12">
        <VueSignaturePad width="200px" height="200px" ref="signaturePad" />
      </div>
      <div v-else class="col-xs-12 col-sm-12" style="text-align: center;">
        <span>🔥 서명이 되지 않았습니다</span>
      </div>
    </template>
  </c-card>
</template>
<script>
export default {
  name: 'safetyGearRecipientSign',
  props: {
    props: {
      type: Object,
      default: function() {
        return {
          row: {},
        }
      },
    },
    col: {
      type: Object,
      default: function() {
        return {}
      },
    },
  },
  data() {
    return {
      signaturePad: null,
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    signFlag() {
      return Boolean(this.props.row['recipientSign'])
    }
  },
  watch: {
  },
  methods: {
    init() {
      if (!this.$refs.signaturePad) {
        return;
      }
      this.signaturePad = this.$refs.signaturePad
      this.signaturePad.lockSignaturePad();

      if (this.signFlag) {
        this.signaturePad.fromDataURL(this.props.row['recipientSign']);
      }
    },
  }
};
</script>
<style lang="sass">
.safetyGearRevipientSign 
  .customCardbody
    margin: 0 !important
</style>